import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appleImg, footerImg, googleImg } from "../../constant";

const Footer = () => {
	const date_holder = new Date();
	const footer_date = date_holder.getFullYear();
	const location = useLocation();

	const [footerLink, setfooterLink] = useState(false);

	return (
		<>
			{/* news letter starts here */}
			<div className="newsletter">
				<div className="container">
					<div className="row">
						<div className="col-lg-5">
							<div className="newsletter-text-wrapper">
								<h2>
									Have A Good
									<span>Koze Shake Day</span>
								</h2>
								<p>
									Mauris auctor eros nec dui blandit pretium. Integer iaculis
									nibh sit amet euismod efficitur. Pellentesque ut nulla
									commodo, dapibus lacus id,
								</p>
							</div>
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-6">
							<div className="newsletter-card-wrapper">
								<div className="newsletter-heading-wrapper">
									<h2>
										Subscribe To Our <span>Newsletter</span>
									</h2>
									<p>
										it amet volutpat nisl magna sit amet urna. Nunc vel nisi
									</p>
									<form action="">
										<div className="newsletter-fields-wrapper">
											<div className="news-input-wrapper">
												<input
													type="email"
													placeholder="Enter Email Address"
													className="form-control"
													required
												/>
											</div>
											<button type="submit" className="btn">
												<span>Submit</span>
												<span className="btn-icon">
													<i className="fa-solid fa-angle-right"></i>
												</span>
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* news letter ends here */}
			{/* Footer starts here */}
			<footer>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3">
							<div className="footer-logo-wrapper">
								<Link to="/">
									<img src={footerImg} className="img-fluids" />
								</Link>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="footer-links-wrapper">
								<div className="footer-menu-card-wrapper">
									<div className="footer-name-wrapper">
										<h5>Product</h5>
									</div>
									<div className="footer-link-wrap">
										<ul>
											<li>
												<Link
													className={
														location.pathname == "/about-us" ? "active" : ""
													}
													to="/about-us"
												>
													About Us
												</Link>
											</li>
											<li>
												<Link
													className={
														location.pathname == "/our-menu" ? "active" : ""
													}
													to="/our-menu"
												>
													Our Menu
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="footer-menu-card-wrapper">
									<div className="footer-name-wrapper">
										<h5>Resources</h5>
									</div>
									<div className="footer-link-wrap">
										<ul>
											<li>
												<Link
													className={
														location.pathname == "/koze-franchise"
															? "active"
															: ""
													}
													to="/koze-franchise"
												>
													Koze Franchises
												</Link>
											</li>
											<li>
												<Link
													className={
														location.pathname == "/contact-us" ? "active" : ""
													}
													to="/contact-us"
												>
													Contact Us
												</Link>
											</li>
										</ul>
									</div>
								</div>
								<div className="footer-menu-card-wrapper">
									<div className="footer-name-wrapper">
										<h5>Legal</h5>
									</div>
									<div className="footer-link-wrap">
										<ul>
											<li>
												<Link
													className={
														location.pathname == "/terms-and-conditions"
															? "active"
															: ""
													}
													to="/terms-and-conditions"
												>
													Terms & Conditions
												</Link>
											</li>
											<li>
												<Link
													className={
														location.pathname == "/privacy-policy"
															? "active"
															: ""
													}
													to="/privacy-policy"
												>
													Privacy Policy
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="footer-last-card">
								<div className="footer-heading">
									<h4>
										Download <span>The App</span>
									</h4>
									<p>Mauris auctor eros nec dui blandit pretium.</p>
								</div>
								<div className="footer-apple-wrapper">
									<div className="buttons-wrapper">
										<Link className="apple-btn" to={"/"}>
											<img src={appleImg} className="img-fluid" />
										</Link>
										<Link className="apple-btn" to={"/"}>
											<img src={googleImg} className="img-fluid" />
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="copyright-text-wrapper">
								<p>© {footer_date}, All Rights Reserved</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* Footer ends here */}
		</>
	);
};

export default Footer;
