import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "../../assets/css/style.css";
import {
	AppScanner,
	aboutImg,
	aboutImg2,
	afterbanner,
	allcookies,
	app1,
	app2,
	appleImg,
	banner1,
	banner2,
	banner3,
	banner5img,
	bannerImg1,
	bannerImg2,
	bannerImg3,
	bannerImg4,
	check,
	coinImg,
	comboImg,
	comboImg1,
	comboImg2,
	comboImg3,
	comboImg4,
	comboImg5,
	comboImg6,
	cook1,
	cook2,
	cook3,
	cook4,
	cook5,
	cook6,
	cookies,
	cookies1,
	cookies2,
	cookies3,
	cookies4,
	cookies5,
	cookies6,
	emotionImg,
	facebook,
	googleImg,
	locationGif,
	playKozeImg,
	playVideo,
	printImg,
	review1,
	review2,
	review3,
	review4,
	review5,
	shake1,
	shake2,
	shake3,
	shake4,
	shake5,
	shake6,
	shakeEmoji,
	videoImg,
} from "../../constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import PlayGame from "../../Components/PlayGame/PlayGame";
import EarnLoyalty from "../../Components/EarnLoyalty/EarnLoyalty";
import Fullpage, {
	FullPageSections,
	FullpageSection,
} from "@ap.cx/react-fullpage";
import { FullpageNavigation } from "@ap.cx/react-fullpage";
import AOS from "aos";
// import "aos/dist/aos.css";
import "animate.css";
import "react-animation/dist/keyframes.css";
import { TypeAnimation } from "react-type-animation";
import { ScrollTrigger } from "gsap/all";
import { ScrollToPlugin } from "gsap/all";
import { CSSRulePlugin } from "gsap/CSSRulePlugin";
import { Power2 } from "gsap";
import { TimelineMax } from "gsap/gsap-core";
import { Linear } from "gsap";
import { css } from "jquery";
import { Power3 } from "gsap";
import { Power4 } from "gsap";
import { ScrollSmoother } from "gsap/all";
import "../../assets/css/koze-home-responsive.css";
const bannerContent = [
	{
		id: 1,
		title: "<h2> Order From <span>App</span> </h2>",
		des: "We’ve Created a brand tailored towards Gen-Z that allows them to interact with the products in an innovative new fashion. Customers have the ability to customize their milkshakes with selfies, personalized messages, and more by using their smart phones",
		img: banner1,
	},
	{
		id: 2,
		title: "<h2> Upload <span>Photo</span> </h2>",
		des: "We’ve Created a brand tailored towards Gen-Z that allows them to interact with the products in an innovative new fashion. Customers have the ability to customize their milkshakes with selfies, personalized messages, and more by using their smart phones",
		img: banner2,
	},
	{
		id: 3,
		title: "<h2>Personalize <span>Shake</span> </h2>",
		des: "We’ve Created a brand tailored towards Gen-Z that allows them to interact with the products in an innovative new fashion. Customers have the ability to customize their milkshakes with selfies, personalized messages, and more by using their smart phones",
		img: banner3,
	},
	{
		id: 4,
		title: "<h2> Voila ! <span>Enjoy</span> </h2>",
		des: "We’ve Created a brand tailored towards Gen-Z that allows them to interact with the products in an innovative new fashion. Customers have the ability to customize their milkshakes with selfies, personalized messages, and more by using their smart phones",
		img: banner5img,
	},
];

const Home = () => {
	const homeSir = useRef(null);
	const [close, Setclose] = useState(false);
	const [active, Setactive] = useState(bannerContent?.[0]);
	const [selectedImage, setSelectedImage] = useState("");
	const [showSlide, SetshowSlide] = useState(false);
	const [anime, setanime] = useState(false);
	console.log(active, "adsd");
	// const navigate = useNavigate();
	// const loc = useLocation();

	// console.log(loc.key);
	// useEffect(() => {
	// 	if (loc.key == "default") {
	// 		document.body.classList.add("home");
	// 	}
	// 	return () => {
	// 		document.body.classList.remove("home");
	// 		document.body.removeAttribute("style");
	// 	};
	// }, [loc.key]);

	// Home class add on home page starts here
	useEffect(() => {
		document.body.classList.add("home");

		return () => {
			document.body.classList.remove("home");
			document.body.removeAttribute("style");
		};
	}, []);
	// Home class add on home page ends here

	const handleSlide = () => {
		SetshowSlide(true);
	};

	const handleClick = (e, data) => {
		e.preventDefault();
		console.log("image is clicked", e.target.id);
		console.log("image is clicked", data);
		Setactive(data);
		console.log(active);
		const imgUrl = e.target.src;
		setSelectedImage(imgUrl);
		const mainImg = document.querySelector(".brand-img").classList.add("main");
		// setanime(true);
		return imgUrl;
	};

	const KozeCookies = [
		{
			id: 1,
			name: "shake 1",
			img: cookies1,
		},
		{
			id: 2,
			name: "shake 2",
			img: cookies2,
		},
		{
			id: 3,
			name: "shake 3",
			img: cookies3,
		},
		{
			id: 4,
			name: "shake 4",
			img: cookies4,
		},
		{
			id: 5,
			name: "shake 5",
			img: cookies5,
		},
		{
			id: 6,
			name: "shake 6",
			img: cookies6,
		},
	];
	const comboshake = [
		{
			id: 1,
			name: "Combo 1",
			img: comboImg1,
		},
		{
			id: 2,
			name: "Combo 2",
			img: comboImg2,
		},
		{
			id: 3,
			name: "Combo 3",
			img: comboImg3,
		},
		{
			id: 4,
			name: "Combo 4",
			img: comboImg4,
		},
		{
			id: 5,
			name: "Combo 5",
			img: comboImg5,
		},
		{
			id: 6,
			name: "Combo 6",
			img: comboImg6,
		},
	];
	const shakecookies = [
		{
			id: 1,
			img: cook1,
		},
		{
			id: 2,
			img: cook2,
		},
		{
			id: 3,
			img: cook3,
		},
		{
			id: 4,
			img: cook4,
		},
		{
			id: 5,
			img: cook5,
		},
		{
			id: 6,
			img: cook6,
		},
	];

	const images = [aboutImg, aboutImg2, aboutImg, aboutImg2];

	const [currentImage, setCurrentImage] = useState("");
	const [scroll, setScroll] = useState(false);

	// canvas icon moves from here
	// useEffect(() => {
	// 	window.addEventListener("scroll", () => {
	// 		var scroll = window.scrollY;
	// 		let element = document.querySelector(".menu-container");
	// 		if (scroll >= 500) {
	// 			element.classList.add("menu-down");
	// 		} else {
	// 			element.classList.remove("menu-down");
	// 		}
	// 		// console.log(scroll++);
	// 	});
	// }, []);
	// canvas icon moves from here
	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentImage(images[Math.floor(Math.random() * images.length)]);
		}, 2000);

		return () => clearInterval(intervalId);
	}, []);

	const ScrollHandler = (e) => {
		e.preventDefault();
		setScroll(!scroll);
		setTimeout(() => {
			setScroll(!scroll);
		}, 2000);
	};
	// gsap.registerPlugin(ScrollTrigger);
	// // gsap.registerPlugin(CSSRulePlugin);
	// gsap.registerPlugin(ScrollToPlugin);
	// useEffect(() => {
	// 	// Original GSAP Demo Pen:
	// 	// https://codepen.io/GreenSock/pen/rNOebyo

	// 	let sections = gsap.utils.toArray(".panel"),
	// 		currentSection = sections[0];

	// 	// stretch out the body height according to however many sections there are.
	// 	gsap.set(".home", { height: sections.length * 100 + "%" });

	// 	// create a ScrollTrigger for each section
	// 	sections.forEach((section, i) => {
	// 		ScrollTrigger.create({
	// 			// use dynamic scroll positions based on the window height (offset by half to make it feel natural)
	// 			pinned: true,
	// 			trigger: sections,
	// 			scrub: true,
	// 			// start: () => (i - 0.1) * window.innerHeight,
	// 			// end: () => (i + 0.1) * window.innerHeight,
	// 			// onToggle: (self) => self.isActive && setSection(section),
	// 			start: () => (i - 0.1) * window.innerHeight,

	// 			end: () => (i + 0.1) * window.innerHeight,
	// 			onToggle: (self) => self.isActive && setSection(section),
	// 		});
	// 	});

	// 	function setSection(newSection) {
	// 		if (newSection !== currentSection) {
	// 			// new section timeline
	// 			var tl = gsap.timeline({
	// 				defaults: {
	// 					duration: 0.5,
	// 				},
	// 			});
	// 			tl.to(newSection, {
	// 				scale: 1,
	// 				autoAlpha: 1,
	// 				ease: Power4.easeInOut,
	// 				newClass: "green",
	// 			}).to(currentSection, { scale: 1.1, autoAlpha: 0 }, "<");

	// 			// .from(newSection.querySelector("h2"), { autoAlpha: 1, y: 50 }, "<");
	// 			// .from(newSection.querySelector("p"), { autoAlpha: 0, y: 20 }, ">");

	// 			currentSection = newSection;
	// 		}
	// 	}
	// 	ScrollTrigger.create({
	// 		start: 1,
	// 	}).scroll(1);

	// 	// manual anchor tags effect
	// 	let offsetPositions = [];
	// 	let container = document.querySelector(".panel");
	// 	let containerWidth = container.offsetHeight;
	// 	// work out what percentage of the distance into the container the box's center is and push that value into an array

	// 	// could we ever get any anchors to work dynamically? Unsure of whether to scrollto an offsetheight or something would work with ScrollToPlugin

	// 	gsap.utils.toArray(".slide2-link").forEach(function (a) {
	// 		a.addEventListener("click", function (e) {
	// 			gsap.to(window, { duration: 0, scrollTo: "#about-sec" });
	// 		});
	// 	});
	// }, []);

	return (
		<>
			<Header Setclose={Setclose} close={close} />

			{/* <div id="pagepiling"> */}
			{/* desktopForceStep={true} */}

			{/* banner starts here */}
			{!showSlide ? (
				<section
					className={
						!showSlide ? "banner banner-trans panel" : "banner-trans panel"
					}
				>
					<div id="ban" className="ban">
						<div className="container">
							<div className="banns">
								<div className="row">
									<div className="col-lg-12">
										<div className="banner-content-wrapper">
											<h1 className="animate__animated animate__fadeInDown ">
												The Original <span>Selfie Shake</span>
											</h1>
											<TypeAnimation
												sequence={[
													// Same substring at the start will only be typed out once, initially
													"Upload Photo",
													3000, // wait 1s before replacing "Mice" with "Hamsters"
													"Personalize Shake",
													3000,
													"Voila! Enjoy",
													3000,
												]}
												wrapper="span"
												speed={50}
												style={{
													fontSize: "2em",
													display: "inline-block",
												}}
												className="pink"
												repeat={Infinity}
											/>
										</div>
									</div>
								</div>
								<div className="banner-imgs-wrapper">
									<div className="row">
										<div className="col-lg-1"></div>
										<div className="col-lg-10">
											<div className="row">
												<div className="col-lg-3">
													<div className="banner-img-wrapper animate__animated animate__fadeInLeft ">
														<figure>
															<img
																src={bannerImg1}
																className="img-fluid"
																alt=""
																onClick={handleSlide}
															/>
														</figure>
													</div>
												</div>
												<div className="col-lg-3">
													<div className="banner-img-wrapper animate__animated animate__zoomIn ">
														<figure>
															<img
																src={bannerImg2}
																className="img-fluid"
																alt=""
																onClick={handleSlide}
															/>
														</figure>
													</div>
												</div>
												<div className="col-lg-3">
													<div className="banner-img-wrapper animate__animated animate__zoomIn ">
														<figure>
															<img
																src={bannerImg3}
																className="img-fluid"
																alt=""
																onClick={handleSlide}
															/>
														</figure>
													</div>
												</div>
												<div className="col-lg-3">
													<div className="banner-img-wrapper animate__animated animate__fadeInRight ">
														<figure>
															<img
																src={bannerImg4}
																className="img-fluid"
																alt=""
																onClick={handleSlide}
															/>
														</figure>
													</div>
												</div>
											</div>
										</div>
										<div className="col-lg-1"></div>
									</div>
								</div>
								{/* <div className="row">
												<div className="col-lg-12">
													<div className="btn-wrapper text-center">
														<Link to="/" className="btn">
															<span>Order Now</span>
															<span className="btn-icon">
																<i className="fa-solid fa-angle-right"></i>
															</span>
														</Link>
													</div>
												</div>
											</div> */}
							</div>

							<div className="row">
								<div className="col-lg-12">
									<div className="banner-social-links-wrapper">
										<ul>
											<li>
												<Link to="/">FACEBOOK</Link>
											</li>
											<li>
												<Link to="/">INSTAGRAM</Link>
											</li>
											<li>
												<Link to="/">YOUTUBE</Link>
											</li>
										</ul>
									</div>
									{/* <div className="vio-wrap">
													<div className="video-btn-wrapper">
														<svg viewBox="0 0 100 100" width="174" height="174">
															<defs>
																<path
																	id="circle"
																	d="M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
																/>
															</defs>
															<animateTransform
																attributeName="transform"
																attributeType="XML"
																type="rotate"
																dur="10s"
																values="0 0 0;360 0 0;"
																repeatCount="indefinite"
															/>
															<text fontSize="17">
																<textPath href="#circle">
																	Watch Video Now • Watch Video Now •
																</textPath>
															</text>
														</svg>
														<img src={videoImg} alt="" />
													</div>
												</div> */}

									<div className="scroll-btn-wrapper">
										{/* <a
														href="#tagline"
														onClick={(e) => ScrollHandler(e)}
														// onClick={scrollToNextSection}
													>
														<i className="fa-solid fa-arrow-left-long"></i>{" "}
														SCROLL DOWN
													</a> */}
										{/* <Link
													activeClass="active"
													to="section2"
													spy={true}
													smooth={true}
													offset={0}
													duration={500}
													className="arrow-down"
												
												>
													TEFXFBDb
												</Link> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : (
				""
			)}

			{showSlide ? (
				<section className="banner bannert2 panel">
					<div className="banner-1-sec ban">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-6">
									<div className="banner-1-text-wrapper ms-lg-5 animate__animated animate__fadeInLeft">
										<span
											dangerouslySetInnerHTML={{
												__html: active?.title,
											}}
										></span>
										<p>{active?.des}</p>
										{/* <div className="btn-wrapper">
											<Link to="/" className="btn">
												<span>Order Now</span>
												<span className="btn-icon">
													<i className="fa-solid fa-angle-right"></i>
												</span>
											</Link>
										</div> */}
									</div>
								</div>
								<div className="col-lg-5">
									<div
										className="banner-1-img-wrapper text-center "
										onClick={() => SetshowSlide(false)}
									>
										{selectedImage ? (
											<figure>
												<img
													src={selectedImage}
													className="img-fluid animate__animated brand-img animate__zoomIn"
													alt=""
												/>
											</figure>
										) : (
											<figure>
												<img
													src={banner1}
													className="img-fluid animate__animated  animate__zoomIn"
													alt=""
												/>
											</figure>
										)}
									</div>
								</div>
								<div className="col-lg-1">
									{/* <div className="banner-side-img-wrapper">
										<ul>
											<li>
												<img
													src={banner1}
													onClick={handleClick}
													className="banner-side img-fluid"
													alt="Order From App"
												/>
											</li>
											<li>
												<img
													src={banner2}
													onClick={handleClick}
													className="banner-side img-fluid"
													alt="Upload Photo"
												/>
											</li>
											<li>
												<img
													src={banner3}
													onClick={handleClick}
													className="banner-side img-fluid"
													alt="Personalize Shake"
												/>
											</li>
											<li>
												<img
													src={banner5img}
													onClick={handleClick}
													className="banner-side img-fluid"
													alt="Personalize Shake"
												/>
											</li>
										</ul>
									</div> */}
									<div className="banner-side-img-wrapper">
										<ul>
											{bannerContent.map((data) => {
												return (
													<>
														{data?.img !== active?.img && (
															<li>
																<img
																	id={data?.id}
																	src={data?.img}
																	className="banner-side img-fluid"
																	onClick={(e) => handleClick(e, data)}
																	alt={data?.title}
																/>
															</li>
														)}
													</>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="banner-social-links-wrapper">
								<ul>
									<li>
										<Link to="/">FACEBOOK</Link>
									</li>
									<li>
										<Link to="/">INSTAGRAM</Link>
									</li>
									<li>
										<Link to="/">YOUTUBE</Link>
									</li>
								</ul>
							</div>

							{/* <div className="vio-wrap">
											<div className="video-btn-wrapper">
												<svg viewBox="0 0 100 100" width="174" height="174">
													<defs>
														<path
															id="circle"
															d="M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
														/>
													</defs>
													<animateTransform
														attributeName="transform"
														attributeType="XML"
														type="rotate"
														dur="10s"
														values="0 0 0;360 0 0;"
														repeatCount="indefinite"
													/>
													<text fontSize="17">
														<textPath href="#circle">
															Watch Video Now • Watch Video Now •
														</textPath>
													</text>
												</svg>
												<img src={videoImg} alt="" />
											</div>
										</div> */}

							{/* <div className="scroll-btn-wrapper">
											<a href="javascript:void(0)">
												<i className="fa-solid fa-arrow-left-long"></i> SCROLL
												DOWN
											</a>
										</div> */}
						</div>
					</div>
				</section>
			) : (
				""
			)}
			{/* banner ends here */}
			{/* after banner slide starts here */}

			{/* after banner slide ends here */}
			{/* about starts here */}
			<section className="about-sec panel" id="about-sec">
				<div className="con">
					<div className="after-banner" id="tagline">
						<div className="container-fluid px-0">
							<div className="row">
								<div className="col-lg-12">
									<div className="after-ban-wrapper">
										<div className="marquee">
											<div>
												<span>
													<figure>
														<img src={afterbanner} className="img-fluid" />
													</figure>
												</span>
												<span>
													<figure>
														<img src={afterbanner} className="img-fluid" />
													</figure>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container-fluid">
						<div className="row align-items-center">
							<div className="col-lg-6 ps-0 animate__animated animate__fadeInLeft ">
								<div className="container">
									<div className="locations-wrapper">
										<button className="location-btn">
											<img src={locationGif} className="img-fluid" alt="" />
											<h6>
												Location <span>01</span>
											</h6>
										</button>
										<button className="location-btn">
											<img src={locationGif} className="img-fluid" alt="" />
											<h6>
												Location <span>02</span>
											</h6>
										</button>
									</div>
								</div>
								<div className="about-img-wrapper">
									<figure>
										<img
											src={currentImage ? currentImage : aboutImg}
											className="img-fluid"
											alt=""
										/>
									</figure>
								</div>
								<div className="container">
									<div className="locations-wrapper">
										<button className="location-btn">
											<img src={locationGif} className="img-fluid" alt="" />
											<h6>
												Location <span>03</span>
											</h6>
										</button>
										<button className="location-btn">
											<img src={locationGif} className="img-fluid" alt="" />
											<h6>
												Location <span>04</span>
											</h6>
										</button>
									</div>
								</div>
							</div>
							<div className="col-lg-5 animate__animated animate__fadeInRight ">
								<div className="about-content-wrapper  ms-lg-4">
									<h6 className="small-heading">GET KOZE !</h6>
									<h2>
										About <span>Koze Shake</span>
									</h2>
									<p>
										We’ve Created a brand tailored towards Gen-Z that allows
										them to interact with the products in an innovative new
										fashion. Customers have the ability to customize their
										milkshakes with selfies, personalized messages, and more by
										using their smart phones
									</p>
								</div>

								<div className="about-testimonials-wrapper ms-lg-4">
									<div className="about-testimonails-heading-wrapper ">
										<h5>Our Happy Customers</h5>
										<p>Placerat orci nulla pell ente</p>
									</div>
									<div className="about-testimonails-img-wrapper">
										<ul>
											<li>
												<img src={review1} className="img-fluid" alt="" />
											</li>
											<li>
												<img src={review2} className="img-fluid" alt="" />
											</li>
											<li>
												<img src={review3} className="img-fluid" alt="" />
											</li>
											<li>
												<img src={review4} className="img-fluid" alt="" />
											</li>
											<li>
												<img src={review5} className="img-fluid" alt="" />
											</li>
										</ul>
									</div>
								</div>
								<div className="about-content-wrapper ms-lg-4">
									<Link to="/about-us" className="btn">
										<span>Learn More</span>
										<span className="btn-icon">
											<i className="fa-solid fa-angle-right"></i>
										</span>
									</Link>
								</div>
							</div>
							<div className="col-lg-1"></div>
						</div>
					</div>
				</div>
			</section>
			{/* about ends here */}
			{/* koze app starts here */}

			<section className="koze-app panel">
				<div className="container">
					<div className="row mb-5 pb-4">
						<div className="col-lg-3"></div>
						<div className="col-lg-6 text-center">
							<div className="shake-heading-wrapper animate__animated animate__fadeInDown ">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									How To Get <span>Koze</span>{" "}
								</h2>
								<p>
									Mauris auctor eros nec dui blandit pretium. Integer iaculis
									nibh sit amet euismod efficitur. Pellentesque ut nulla
									commodo, dapibus lacus id, pharetra mi. Praesent
								</p>
							</div>
						</div>
						<div className="col-lg-3"></div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="koze-app-1-wrapper animate__animated animate__fadeInLeft ">
								<div className="koze-app-content-wrapper">
									<h6 className="small-heading">KOZE APP</h6>
									<h4>Order From The App</h4>
									<div className="app-list-wrapper">
										<ul>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Order from App</span>
											</li>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Mark arrive on location</span>
											</li>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Grab your shake</span>
											</li>
										</ul>
									</div>
									<div className="buttons-wrapper">
										<Link className="apple-btn" to={"/"}>
											<img src={appleImg} className="img-fluid" />
										</Link>
										<Link className="apple-btn" to={"/"}>
											<img src={googleImg} className="img-fluid" />
										</Link>
									</div>
								</div>
								<div className="koze-app-img-wrapper">
									<img src={app1} className="img-fluid" alt="" />
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="koze-app-2-wrapper animate__animated animate__fadeInRight ">
								<div className="koze-app-content-wrapper">
									{/* <h6 className="small-heading">GET KOZE !</h6> */}
									<h6 className="small-heading">KOZE ONLINE</h6>

									<h4>Order From The Location</h4>
									<div className="app-list-wrapper">
										<ul>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Scan QR Code</span>
											</li>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Browse the menu</span>
											</li>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Place order online</span>
											</li>
											<li>
												<img src={check} className="img-fluid" alt="" />{" "}
												<span>Grab your shake</span>
											</li>
										</ul>
									</div>
								</div>
								<div className="koze-app-img-wrapper">
									<img src={app2} className="img-fluid" alt="" />
									<img src={AppScanner} className="app-scanner-img" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* koze app ends here */}
			{/* print sec starts here */}

			<section className="print-sec panel">
				<div className="container">
					<div className="row align-items-center flex-column-reverse flex-lg-row">
						<div className="col-lg-6">
							<div className="print-content-wrapper animate__animated animate__fadeInLeft  me-lg-4">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Print <span>Anything</span>
								</h2>
								<h4>On Top Of Shakes</h4>
								<p className="pt-2">
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vitae aliquet nec ullamcorper. Euismod quis v
								</p>
								<p>
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									amet aliqua
								</p>
							</div>
						</div>
						<div className="col-lg-6">
							<figure>
								<img
									src={printImg}
									className="img-fluid animate__animated animate__fadeInRight "
								/>
							</figure>
						</div>
					</div>
				</div>
			</section>
			{/* print sec ends here */}
			{/* emotions sec starts here */}
			<section className="emotions-sec panel">
				<div className="container">
					<div className="row align-items-center ">
						<div className="col-lg-6 animate__animated animate__fadeInLeft ">
							<figure className="img-container">
								<img src={emotionImg} className="img-fluid emotion-img" />
							</figure>
						</div>
						<div className="col-lg-6 animate__animated animate__fadeInRight ">
							<div className="emotions-content-wrapper ms-lg-4">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Express Your <span>Emotions</span>
								</h2>
								<p className="pt-2">
									Placerat orci nulla pellentesque dignissim enim sit amet
									venenatis. Bibendum enim facilisis gravida neque convallis.
									Pharetra diam sit amet nisl suscipit adipiscing bibendum est.
									Ipsum faucibus vita
								</p>
								<p>
									vel eros. At quis risus sed vulputate odio. Turpis massa
									tincidunt dui ut ornare lectus sit amet est. Ut pharetra sit
									am
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* emotions sec ends here */}
			{/* <div className="wrapper"> */}
			{/* play games starts here */}
			{/* <div className="comp comp1"> */}
			<PlayGame />
			{/* </div> */}
			{/* play games ends here */}
			{/* earnn loyalty starts here */}
			{/* <div className="comp comp2"> */}

			{/* <EarnLoyalty /> */}
			{/* </div> */}
			{/* earnn loyalty ends here */}
			{/* </div> */}
			{/* shake starts here */}

			<section className="shake-sec panel">
				<div className="container">
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8 text-center">
							<div className="shake-heading-wrapper animate__animated animate__fadeInDown ">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Meet Koze <span>Shake</span>{" "}
								</h2>
								<p>
									Mauris auctor eros nec dui blandit pretium. Integer iaculis
									nibh sit amet euismod efficitur. Pellentesque ut nulla
									commodo, dapibus lacus id, pharetra mi. Praesent
								</p>
							</div>
						</div>
						<div className="col-lg-2"></div>
					</div>
					<div className="row">
						<div className="col-lg-6 animate__animated animate__fadeInLeft ">
							<div className="shake-biscuits-wrapper">
								<ul>
									<li>
										<img src={shake1} className="img-fluid" />
									</li>
									<li>
										<img src={shake2} className="img-fluid" />
									</li>
									<li>
										<img src={shake3} className="img-fluid" />
									</li>
									<li>
										<img src={shake4} className="img-fluid" />
									</li>
									<li>
										<img src={shake5} className="img-fluid" />
									</li>
									<li>
										<img src={shake6} className="img-fluid" />
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6 align-self-center">
							<div className="shake-biscuits-wrapper animate__animated animate__fadeInRight ">
								<img src={allcookies} className="img-fluid" alt="" />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="button-wrapper text-center">
								<a href="/our-menu" className="btn">
									<span>View All</span>
									<span className="btn-icon">
										<i className="fa-solid fa-angle-right"></i>
									</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* shake ends here */}
			{/* combo-shake starts here */}

			{/* <section className="combo-shake panel">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="combo-shake-img-wrapper animate__animated animate__fadeInLeft ">
                <figure>
                  <img src={comboImg} className="img-fluid" />
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="combo-shake-content-wrapper animate__animated animate__fadeInRight ">
                <h6 className="small-heading">GET KOZE !</h6>
                <h2>
                  Koze <span>Shake</span>
                </h2>
                <p className="pt-2">
                  Mauris auctor eros nec dui blandit pretium. Integer iaculis
                  nibh sit amet euismod efficitur. Pellentesque ut nulla
                  commodo, dapibus lacus id,
                </p>
                <div className="combo-shake-wrapper">
                  <ul>
                    {comboshake.map((data) => {
                      return (
                        <>
                          <li key={data?.id}>
                            <div className="combo-shake-card-wrapper">
                              <div className="combo-shake-img-wrapper">
                                <img
                                  src={data?.img}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="combo-shake-koze-heading">
                                <h5>{data?.name}</h5>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
			{/* combo-shake ends here */}
			{/* Koze cookies starts here */}

			<section className="koze-cookies panel">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 animate__animated animate__fadeInLeft ">
							<div className="koze-cookies-img-wrapper">
								<figure>
									<img src={cookies} className="img-fluid" />
								</figure>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="koze-cookies-content-wrapper animate__animated animate__fadeInRight ">
								<h6 className="small-heading">GET KOZE !</h6>
								<h2>
									Koze <span>Cookies</span>
								</h2>
								<p className="pt-2">
									Mauris auctor eros nec dui blandit pretium. Integer iaculis
									nibh sit amet euismod efficitur. Pellentesque ut nulla
									commodo, dapibus lacus id,
								</p>
								<div className="cookies-shake-wrapper">
									<ul>
										{KozeCookies.map((data, index) => {
											return (
												<>
													<li key={data?.id}>
														<div className="cookies-card-wrapper">
															<div className="cookies-img-wrapper">
																<img
																	src={data?.img}
																	className="img-fluid"
																	alt=""
																/>
															</div>
															<div className="cookies-koze-heading">
																<h5>{data?.name}</h5>
															</div>
														</div>
													</li>
												</>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			{/* Koze cookies ends here */}
			{/* </div> */}

			{/* <div className="home-footer-wrapper"> */}
			<section className="footer-wrapper panel">
				<Footer />
			</section>
		</>
	);
};

export default Home;
